/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ChangeVideoDuration from './ChangeVideoDuration';
import moment from 'moment';
import {
  useDeleteDocumentMutation,
  useDeleteVideoMutation,
} from '../../../app/services/CoursesApi';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import DisplayDocument from './DisplayDocument';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';

function DisplayCourseDocuments(props) {
  const { document } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(false);
  const [deleteDocument, deletionResult] = useDeleteDocumentMutation();

  const viewDocument = (doc) => {
    setSelectedDocument(doc);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onDelete = (id) => {
    const res = deleteDocument({ id });
  };

  if (deletionResult.isError) {
    enqueueSnackbar(`${deletionResult.error.data.message}`, {
      variant: 'error',
    });
  }
  if (deletionResult.isSuccess) {
    enqueueSnackbar(`Document Deleted Successfully`, {
      variant: 'success',
    });
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={document.id}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                <Button variant="contained" onClick={() => viewDocument(document)}>
                  {document.title}&nbsp;
                  {<DocumentScannerOutlinedIcon />}
                </Button>{' '}
              </TableCell>

              <TableCell>{document.status}</TableCell>

              <TableCell>
                <Button
                  // sx={{ pl: 4 }}
                  onClick={() => onDelete(document.id)}
                  endIcon={<DeleteIcon />}
                  variant="contained"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <>
        {selectedDocument ? (
          <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
            <DialogTitle> View {selectedDocument.title} </DialogTitle>
            <DialogContent>
              <DisplayDocument doc={selectedDocument} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    </>
  );
}

export default DisplayCourseDocuments;
