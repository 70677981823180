/* eslint-disable no-unused-vars */
import { ArrowForwardIos, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  Card,
  CardContent,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import styled from '@emotion/styled';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIos />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  // '& .MuiAccordionSummary-content': {
  //   marginLeft: theme.spacing(1),
  // },
}));
function DisplayCourseCurriculum(props) {
  const { subjects, height } = props;

  return (
    <Paper
      sx={{
        m: 1,
        height: subjects.length > 12 && height,
        overflowY: subjects.length > 12 && height && 'scroll',
      }}
    >
      {subjects.length >= 1 ? (
        <>
          {subjects.map((singleSubject, index) => (
            <>
              <Accordion
                key={singleSubject.id}
                style={props.mainAccordion ? props.mainAccordion : {}}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={props.accordionSummary ? props.accordionSummary : {}}
                >
                  <Typography sx={{ width: '90%' }}>
                    {index + 1}. {singleSubject.title}
                  </Typography>
                  {singleSubject.published_video ? (
                    <Typography sx={{ color: 'text.secondary' }}>
                      {moment
                        .utc(singleSubject.published_video?.duration * 1000)
                        .format('HH:mm:ss')}
                    </Typography>
                  ) : null}
                </AccordionSummary>
                <AccordionDetails
                  style={
                    props?.accordionDetailsStyle
                      ? {
                          ...(props?.accordionDetailsStyle || {}), // Spread the props object if it exists
                          ...(subjects.length - 1 === index
                            ? { borderBottom: '1px solid #d1d7dc' }
                            : {}),
                        }
                      : {}
                  }
                >
                  {singleSubject.topics.length >= 1 &&
                    singleSubject.topics.map((singleTopic, i) => (
                      <>
                        {singleTopic.sub_topics.length >= 1 ? (
                          <Accordion key={singleTopic.id}>
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography sx={{ width: '90%' }}>
                                {index + 1}. {i + 1}. {singleTopic.title}
                              </Typography>
                              {singleTopic.published_video ? (
                                <Typography sx={{ color: 'text.secondary' }}>
                                  {moment
                                    .utc(
                                      singleTopic.published_video?.duration * 1000
                                    )
                                    .format('HH:mm:ss')}
                                </Typography>
                              ) : null}
                              {singleTopic.published_document ? (
                                <Typography sx={{ color: 'text.secondary' }}>
                                  <DocumentScannerOutlinedIcon />
                                </Typography>
                              ) : null}
                            </AccordionSummary>
                            <AccordionDetails>
                              {singleTopic.sub_topics.length >= 1 &&
                                singleTopic.sub_topics.map((singleSubTopic, ii) => (
                                  <Accordion key={singleSubTopic.id}>
                                    <AccordionSummary
                                      // expandIcon={<KeyboardArrowDownIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography sx={{ width: '90%' }}>
                                        {index + 1}. {i + 1}. {ii + 1}.{' '}
                                        {singleSubTopic.title}
                                      </Typography>
                                      <Typography sx={{ color: 'text.secondary' }}>
                                        {moment
                                          .utc(
                                            singleSubTopic.published_video
                                              ?.duration * 1000
                                          )
                                          .format('HH:mm:ss')}
                                      </Typography>
                                    </AccordionSummary>
                                  </Accordion>
                                ))}
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          <AccordionSummary
                            expandIcon={''}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography sx={{ width: '90%' }}>
                              {index + 1}. {i + 1}. {singleTopic.title}
                            </Typography>
                            {singleTopic.published_video ? (
                              <Typography sx={{ color: 'text.secondary' }}>
                                {moment
                                  .utc(singleTopic.published_video?.duration * 1000)
                                  .format('HH:mm:ss')}
                              </Typography>
                            ) : null}
                            {singleTopic.published_document ? (
                              <Typography sx={{ color: 'text.secondary' }}>
                                <DocumentScannerOutlinedIcon />
                              </Typography>
                            ) : null}
                          </AccordionSummary>
                        )}
                      </>
                    ))}
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </>
      ) : null}
    </Paper>
  );
}

export default DisplayCourseCurriculum;
