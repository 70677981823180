/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import {
  ArrowDropDown,
  CheckBox,
  ExpandMore,
  FormatListBulleted,
} from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  TablePagination,
  Menu,
  MenuItem,
  Button,
  ClickAwayListener,
  Grow,
  Popper,
  ButtonGroup,
  MenuList,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  useDeleteCourseMutation,
  useUpdateCourseSatusMutation,
} from '../../../app/services/CoursesApi';

import { styled, alpha } from '@mui/material/styles';
import { groupBy, toUpper } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { TableVirtuoso } from 'react-virtuoso';
import ChangeCourseStatus from './admin/ChangeCourseStatus';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const columns = [
  {
    width: 40,
    label: 'Sr. No',
  },
  {
    width: 70,
    label: 'Course ID',
  },
  {
    width: 200,
    label: 'Title',
  },
  {
    width: 120,
    label: 'Status',
  },
  {
    width: 120,
    label: 'Category',
  },
  {
    width: 120,
    label: 'Sub Category',
  },
  {
    width: 120,
    label: 'Created On',
  },
  {
    width: 120,
    label: 'Action',
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{
            // backgroundColor: 'background.paper',
            background: '#10496a',
            color: '#fff',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function generateGroupedVideos(courses) {
  const groupedCourses = groupBy(courses, (course) => course.title[0]);
  const groupCounts = Object.values(groupedCourses).map((users) => users.length);
  const groups = Object.keys(groupedCourses);

  return { groupCounts, groups };
}

function CoursesList(props) {
  const { courses } = props;
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const virtuoso = useRef(null);
  const [selectedCourse, setSelectedCourse] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [createEL, setCreateEL] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const open = Boolean(anchorEl);
  const createOpen = Boolean(createEL);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [filteredCourses, setFilteredCourse] = useState(courses ? courses : []);

  const [isOpen1, setIsOpen1] = useState(false);

  const changeStatus = (e) => {
    e.stopPropagation();
    setIsOpen1(true);
  };

  const handleClose1 = () => {
    setIsOpen1(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleMenuClick = (event, course) => {
    setSelectedCourse(course);
    setAnchorEl(event.currentTarget);
  };
  const handleCreateMenuClick = (event) => {
    setCreateEL(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseCreate = () => {
    setCreateEL(null);
  };
  const [expandedId, setExpandedId] = React.useState(-1);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [updateCourseStatus, result] = useUpdateCourseSatusMutation();

  const [deleteCourse, deletionResult] = useDeleteCourseMutation();

  const viewCourse = (id) => {
    navigate(`/courses/${id}`);
  };
  const editCourse = (id) => {
    navigate(`/edit-courses/${id}`);
  };

  // const onStatusChange = (id, status) => {
  //   const res = updateCourseStatus({ id, status });
  // };

  const onDelete = (id) => {
    const res = deleteCourse({ id });
    handleCloseDialog();
  };

  const onChangeStatus = (event) => {
    event.preventDefault();
    const updateData = {
      id: selectedCourse.id,
      status: 'publish_request',
      published_at: null,
    };

    updateCourseStatus(updateData)
      .unwrap()
      .then((res) => {
        if (res.message === 'status updated successfully') {
          enqueueSnackbar('Requested To Publish Success', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Error Requesting to publish ', {
            variant: 'error',
          });
        }
      });
  };

  const { groups, groupCounts } = generateGroupedVideos(filteredCourses);
  const renderMenuItems = (selectedCourse) => {
    return selectedCourse ? (
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            viewCourse(selectedCourse.id);
            handleClose();
          }}
          sx={{ background: '#f9f5f5', borderBottom: '1px soild #fff' }}
        >
          Preview
        </MenuItem>
        {user?.role === 'admin' ? (
          <MenuItem
            onClick={(e) => changeStatus(e, selectedCourse)}
            sx={{ background: '#f9f5f5' }}
          >
            Change Status
          </MenuItem>
        ) : null}
        {selectedCourse?.status === 'draft' && user?.role === 'educator' ? (
          <MenuItem onClick={onChangeStatus} sx={{ background: '#f9f5f5' }}>
            Request To Publish
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            editCourse(selectedCourse.id);
            handleClose();
          }}
          sx={{ background: '#f9f5f5' }}
        >
          Edit Course
        </MenuItem>
        {selectedCourse.status !== 'published' ? (
          <MenuItem
            onClick={() => {
              handleClickOpenDialog();
              // onDelete(selectedCourse.id);
              // handleClose();
            }}
            sx={{ background: '#f9f5f5' }}
          >
            Delete Course
          </MenuItem>
        ) : null}
      </StyledMenu>
    ) : null;
  };
  const rendeCreaterMenuItems = () => {
    return (
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={createEL}
        open={createOpen}
        onClose={handleCloseCreate}
      >
        <MenuItem
          onClick={() => {
            navigate('/add-course');
            handleCloseCreate();
          }}
          sx={{ background: '#def0f5', borderBottom: '1px soild #fff' }}
        >
          Create Recorded Course
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/add-course');
            handleCloseCreate();
          }}
          sx={{ background: '#def0f5', borderBottom: '1px soild #fff' }}
        >
          Create Live Course
        </MenuItem>
      </StyledMenu>
    );
  };
  return (
    <>
      {/* <Typography
        component="div"
        variant="h5"
        sx={{ color: '#ef3768', fontSize: '18px', fontWeight: 600 }}
      >
        My Courses
      </Typography> */}
      {/* <ul
        style={{
          marginLeft: '0.5rem',
          paddingLeft: '0',
          listStyle: 'none',
          fontSize: '0.8rem',
          display: 'flex',
        }}
      >
        {groupCounts
          .reduce(
            ({ firstItemsIndexes, offset }, count) => {
              return {
                firstItemsIndexes: [...firstItemsIndexes, offset],
                offset: offset + count,
              };
            },
            { firstItemsIndexes: [], offset: 0 }
          )
          .firstItemsIndexes.map((itemIndex, index) => (
            <li key={index}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  virtuoso.current.scrollToIndex({
                    index: itemIndex,
                  });
                }}
              >
                {toUpper(groups[index])}
              </a>
              &nbsp;
            </li>
          ))}
      </ul>{' '} */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {[
            { displayName: 'Draft', name: 'draft' },
            { displayName: 'In Review', name: 'in_review' },
            { displayName: 'Published', name: 'published' },
            { displayName: 'On Hold', name: 'on_hold' },
            { displayName: 'Rejected', name: 'rejected' },
            {
              displayName: 'Requested To Publish',
              name: 'publish_request',
            },
          ].map((item, ind) => (
            <Typography
              sx={{
                color: selectedStatus == item?.name ? '#fff' : '#300c1e',
                fontSize: '16px',
                lineHeight: '32px',
                width: item?.name == 'publish_request' ? '200px' : '100px',
                background: selectedStatus == item?.name ? '#10496a' : '#def0f5',
                paddingLeft: '1rem',
                paddingTop: '0.3rem',
                paddingBottom: '0.3rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSelectedStatus(item?.name);

                const finalData =
                  Array.isArray(courses) &&
                  courses.length > 0 &&
                  courses.filter((elem) => elem.status === item?.name);

                setFilteredCourse([...finalData]);
              }}
              onChange={() => console.log('first')}
              key={'key' + ind}
            >
              {item?.displayName}
            </Typography>
          ))}
        </div>
        <div>
          <Button
            id="demo-customized-button"
            aria-controls={createOpen ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={createOpen ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleCreateMenuClick}
            endIcon={<KeyboardArrowDownIcon />}
            // startIcon={<FormatListBulleted />}
            sx={{ background: '#09768d' }}
          >
            Create Course
          </Button>
          {rendeCreaterMenuItems()}
        </div>
      </div>
      {courses.length > 0 ? (
        <Paper style={{ height: 400, width: '100%' }}>
          <TableVirtuoso
            data={filteredCourses}
            ref={virtuoso}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            checkboxSelection
            className="course-list-table"
            itemContent={(index, singleCourse) => (
              <>
                {' '}
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ padding: '8px', color: '#0c778e' }}
                >
                  {/* <CheckBox {...{ inputProps: { 'aria-label': 'Checkbox demo' } }} />{' '} */}
                  {index + 1}
                </TableCell>
                <TableCell sx={{ padding: '8px', color: '#0c778e' }}>
                  {singleCourse.id}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                  }}
                  title={singleCourse?.title}
                >
                  {singleCourse.title}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                  }}
                  title={singleCourse.status || 'draft'}
                >
                  {singleCourse.status || 'draft'}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                  }}
                  title={
                    singleCourse.sub_category
                      ? singleCourse.sub_category.category.title
                      : null
                  }
                >
                  {singleCourse.sub_category
                    ? singleCourse.sub_category.category.title
                    : null}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                  }}
                  title={
                    singleCourse.sub_category
                      ? singleCourse.sub_category.title
                      : null
                  }
                >
                  {singleCourse.sub_category
                    ? singleCourse.sub_category.title
                    : null}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    color: '#083faf',
                    padding: '8px',
                  }}
                  title={moment(singleCourse.created_at).format('Do MMM YY hh:mm A')}
                >
                  {moment(singleCourse.created_at).format('Do MMM YY hh:mm A')}
                </TableCell>
                <TableCell
                  sx={{
                    padding: '8px',
                  }}
                >
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(e) => handleMenuClick(e, singleCourse)}
                    endIcon={<KeyboardArrowDownIcon />}
                    // startIcon={<FormatListBulleted />}
                    sx={{ background: '#09768d' }}
                  >
                    Action
                  </Button>
                  {renderMenuItems(selectedCourse)}
                </TableCell>
              </>
            )}
          />
        </Paper>
      ) : (
        <p>No courses found</p>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this course
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => {
              onDelete(selectedCourse.id);
              handleClose();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {selectedCourse ? (
        <Dialog open={isOpen1} onClose={handleClose1}>
          <DialogTitle> Change {selectedCourse.title} Status</DialogTitle>
          <DialogContent>
            <ChangeCourseStatus course={selectedCourse} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

export default CoursesList;
